import React, { useState, useEffect } from 'react'
import { Table, Spin } from 'antd'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function ActivitySuggestions() {
  const [loading, setLoading] = useState(false)
  const [submissions, setSubmissions] = useState([])

  const getSubmissions = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/getActivitySuggestions`)

      setSubmissions(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubmissions()
  }, [])

  const columns = [
    {
      title: 'Sub Title',
      dataIndex: 'subTitle',
      key: 'subTitle',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => <div>{record.subTitle}</div>,
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => <div>{record.points}</div>,
    },
    {
      title: 'Quick Description',
      dataIndex: 'quickDescription',
      key: 'quickDescription',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => <div>{record.quickDescription}</div>,
    },
    {
      title: 'Added by',
      dataIndex: 'user',
      key: 'user',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => (
        <div>
          <a href={`/member/${record.user?._id}`} target="_blank">
            {record.user?.firstName}
          </a>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
  ]

  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <h2>Activity Suggestions</h2>
        {loading ? (
          <Spin />
        ) : (
          <>
            <p>Count: {submissions.length}</p>
            <Table columns={columns} dataSource={submissions} pagination={{ pageSize: 5 }} scroll={{ x: 300 }} />
          </>
        )}
      </div>
    </Layout>
  )
}

export default ActivitySuggestions
