import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { MenuFoldOutlined, MenuUnfoldOutlined, HomeOutlined, UserOutlined, GiftOutlined, TeamOutlined, MinusOutlined, BarChartOutlined, ProductOutlined } from '@ant-design/icons'
import { Button, Layout, Menu } from 'antd'

const { Header, Sider, Content } = Layout

function Home({ children }) {
  const navigate = useNavigate()
  const location = useLocation()

  const [collapsed, setCollapsed] = useState(false)
  const [menuKey, setMenuKey] = useState(location?.pathname?.substring(1))

  const onClick = (e) => {
    navigate(`/${e.key}`)
    setMenuKey(e.key)
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Menu
          onClick={onClick}
          theme="dark"
          mode="inline"
          // defaultSelectedKeys={['home']}
          selectedKeys={[menuKey]}
          items={[
            {
              key: 'home',
              icon: <HomeOutlined />,
              label: 'Home',
            },
            {
              key: 'members',
              icon: <TeamOutlined />,
              label: 'Members',
            },
            {
              key: 'referrals',
              icon: <GiftOutlined />,
              label: 'Referrals',
            },
            {
              key: 'focusAreas',
              icon: <MinusOutlined />,
              label: 'Focus Areas',
            },
            {
              key: 'profile',
              icon: <UserOutlined />,
              label: 'Profile',
            },
            {
              key: 'logs',
              icon: <BarChartOutlined />,
              label: 'Logs',
            },
            {
              key: 'activitySuggestions',
              icon: <ProductOutlined />,
              label: 'Activity Suggestions',
            },
            // {
            //   key: '3',
            //   label: 'EMPTY',
            // },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: 'white',
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: 'white',
            borderRadius: 20,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default Home
